export const AVATAR_COOKIE_NAME = "avatar-v1"
export const EXPERIMENTS_COOKIE_NAME = "experiments-v1"
export const GUEST_FREE_CREDITS = 5_000
export const FREE_CREDITS_PER_UNLOCK = 5_000

export const defaultProfile = {
  tier: "guest",
  status: "active",
  prepaidUsage: 0,
  prepaidLimit: GUEST_FREE_CREDITS,
  customClaims: {},
}

export function getAppUrl(app) {
  if (app === "ct") {
    return import.meta.env.PUBLIC_CT_APP_URL
  }

  if (app === "ycd") {
    return import.meta.env.PUBLIC_YCD_APP_URL
  }
}

export function getApiUrl(app) {
  if (app === "ct") {
    return import.meta.env.PUBLIC_CT_API_URL
  }

  if (app === "ycd") {
    return import.meta.env.PUBLIC_YCD_API_URL
  }
}
